import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import BlockDefault from 'components/elements/Blocks/BlockDefault'

import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import ButtonPrimaryBig from 'components/elements/Buttons/ButtonPrimaryBig'

// Images
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Wrapper = styled.div<{ hasImage: boolean }>`
  ${(props) =>
    !props.hasImage &&
    css`
      background: ${({ theme }) => theme.color.primary};

      @media (min-width: 992px) {
        min-height: 345px;
      }
      @media (max-width: 991px) {
        min-height: 245px;
      }
    `}
  ${(props) =>
    props.hasImage &&
    css`
      @media (min-width: 992px) {
        min-height: 445px;
      }
    `}
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 550px;
  }
  @media (max-width: 991px) {
    height: 200px;
  }
`

const Content = styled(ParseContent)<{ hasImage: boolean }>`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 100px;
    line-height: 85px;
    ${(props) =>
      props.hasImage
        ? css`
            font-size: 100px;
            text-shadow: 2px 2px 14px rgba(0, 0, 0, 1);
          `
        : css`
            font-size: 75px;
          `}
  }

  ${(props) =>
    props.hasImage &&
    css`
      @media (min-width: 992px) {
        padding-top: 10rem;
      }
      @media (max-width: 991px) {
        padding-top: 3rem;
      }
    `}
`

interface HighlightedStoriesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const HighlightedStories: React.FC<HighlightedStoriesProps> = ({ fields }) => (
  <section className="mb-5 pb-5">
    <Wrapper
      className="position-relative d-flex h-100 flex-column justify-content-center"
      hasImage={fields.image || false}
    >
      <div className="d-flex justify-content-center position-relative">
        {fields.image && (
          <Image image={fields.image} alt="" className="position-absolute" />
        )}
      </div>
      <div className="container mb-5">
        <Content
          content={fields.description}
          hasImage={fields.image}
          className="position-relative mb-5 pb-lg-5"
        />
      </div>
    </Wrapper>
    <Blog fields={fields} hasImage={fields.image} />
  </section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
  hasImage: boolean
}

const Blog: React.FC<BlogProps> = ({ fields, hasImage }) => {
  const {
    allWpStory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedStoriesQueryQuery>(graphql`
    query highlightedStoriesQuery {
      allWpStory(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
            storydetail {
              description
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpStory.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Stories"
      showIds={fields?.relationstory?.map(({ databaseId }: any) => databaseId)}
      limit={Infinity}
    >
      <BlogGrid fields={fields} hasImage={hasImage} />
    </BlogBink>
  )
}

const PostsWrapper = styled.div<{ hasImage: boolean }>`
  ${(props) =>
    props.hasImage
      ? css`
          @media (min-width: 992px) {
            margin-top: 1rem;
          }
          @media (max-width: 991px) {
            margin-top: -3rem;
          }
        `
      : css`
          @media (min-width: 992px) {
            margin-top: -10rem;
          }
          @media (max-width: 991px) {
            margin-top: -6rem;
          }
        `}
  position: relative;
  z-index: 4;
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
  hasImage: boolean
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields, hasImage }) => {
  const blogBink = useBlogBink()

  return (
    <PostsWrapper className="container" hasImage={hasImage}>
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="row">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div key={post.node.id} className="col-md-6 d-flex mb-5">
                  <BlogGridPost node={node} blogFields={fields} />
                </div>
              )
            })}
            <div className="d-flex justify-content-center pt-md-5">
              <ButtonSecondaryTransparent to={fields?.link?.url || '/'}>
                {fields?.link?.title}
              </ButtonSecondaryTransparent>
            </div>
          </div>
        </div>
      </div>
    </PostsWrapper>
  )
}

const PostContent = styled(ParseContent)`
  & p,
  & span {
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`

const Title = styled.h3`
  margin-bottom: 2rem;
  line-height: 32px;
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const { storydetail } = node

  return (
    <BlockDefault noHoverEffect className="mx-lg-3 mb-5 mb-md-0 py-5">
      <div className="h-100">
        <div className="h-100 px-lg-5">
          <div className="px-sm-2  h-100">
            <Title className="text-center">{node.title}</Title>
            <div className="mt-auto h-75">
              <PostContent content={storydetail.description} className="pb-2" />
              <div className="d-flex justify-content-end justify-content-sm-center mt-4">
                <ButtonPrimaryBig to={node.uri}>Lees meer</ButtonPrimaryBig>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockDefault>
  )
}

export default HighlightedStories
