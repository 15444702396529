import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'

// Elements
import Plaatjie from '@ubo/plaatjie'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import ButtonSecondaryTransparent from 'components/elements/Buttons/ButtonSecondaryTransparent'
import ParseContent from 'components/shared/ParseContent'

interface HighlightedMembersProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const HighlightedMembers: React.FC<HighlightedMembersProps> = ({ fields }) => (
  <section className="my-5 py-5">
    <Blog fields={fields} />
  </section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpMember,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedMemberQueryQuery>(graphql`
    query highlightedMemberQuery {
      allWpMember(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
            memberdetail {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 1400)
                  }
                }
              }
              fieldGroupName
            }
          }
        }
      }
    }
  `)

  const posts: unknown = allWpMember.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Members"
      showIds={fields?.relationmember?.map(({ databaseId }: any) => databaseId)}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.quote};
  }
`

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <Content content={fields.description} />
      <div className="row mt-5 justify-content-center">
        <div className="col-sm-11">
          <div className="row">
            {blogBink.posts.map((post) => {
              const { node }: any = post

              return (
                <div
                  key={post.node.id}
                  className="col-sm-4 col-6 mb-5 d-flex justify-content-center"
                >
                  <BlogGridPost node={node} />
                </div>
              )
            })}
            <div className="d-flex justify-content-center">
              <ButtonSecondaryTransparent to={fields?.link?.url || '/'}>
                {fields?.link?.title}
              </ButtonSecondaryTransparent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PostWrapper = styled.div``

const Image = styled(Plaatjie)`
  @media (min-width: 1399px) {
    width: 330px;
    height: 445px;
  }
`
interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    // eslint-disable-next-line
    memberdetail: GatsbyTypes.WpMember_Memberdetail
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <PostWrapper>
    <motion.div className="position-relative">
      <Image image={node.memberdetail.image} alt="" />
    </motion.div>
  </PostWrapper>
)

export default HighlightedMembers
